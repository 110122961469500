import * as React from "react"
import { Link } from "gatsby"
import { Row, Col, Button, Card, Alert } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import usePagination from "./pagination"
import { format, parseISO } from "date-fns"
import { pt } from "date-fns/locale"

import "../assets/css/articles.css"

const ArticlesComponent = ({ data }) => {
  const PER_PAGE = 12
  const pageCount = Math.ceil(data.length / PER_PAGE)
  const Paginate = usePagination(data, PER_PAGE)
  return (
    <React.Fragment>
      {Paginate.currentData().length === 0 && (
        <Row>
          <Col>
            <Alert variant="primary" className="w-100">
              Nenhum post encontrado.
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        {Paginate.currentData().map(article => {
          const created_at = parseISO(article.created_at)
          return (
            <>
              <Col md={6} className="article article-card mt-2 mb-4">
                <Card className="h-100">
                  {article.image && (
                    <Link to={`/blog/artigo/${article.slug}`}>
                      <GatsbyImage
                        image={
                          article.image.imageFile.childImageSharp
                            .gatsbyImageData
                        }
                        alt=""
                      />
                    </Link>
                  )}
                  <Card.Body className="bg-dark-grey">
                    <div className="d-flex align-items-center article-title">
                      <div className="date-square">
                        <div>
                          <span className="day">
                            {format(created_at, "dd")}
                          </span>
                          <span className="month text-uppercase">
                            {format(created_at, "MMM", { locale: pt })}
                          </span>
                        </div>
                      </div>
                      <div>
                        <h4>{article.title}</h4>
                        <div className="separator" />
                      </div>
                    </div>
                    <div
                      className="article-content"
                      dangerouslySetInnerHTML={{ __html: article.subtitle }}
                    ></div>
                  </Card.Body>
                  <Card.Footer>
                    <Link
                      className="btn btn-primary btn-sm"
                      to={`/blog/artigo/${article.slug}`}
                    >
                      Leia mais
                    </Link>
                  </Card.Footer>
                </Card>
              </Col>
            </>
          )
        })}
      </Row>
      {data.length > PER_PAGE && (
        <Row className="mt-4">
          <Col className="text-left">
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                Paginate.prev()
              }}
              disabled={Paginate.currentPage === 1 ? true : false}
            >
              Anterior
            </Button>
          </Col>
          <Col className="text-right">
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                Paginate.next()
              }}
              disabled={Paginate.currentPage === pageCount ? true : false}
            >
              Próxima
            </Button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

export default ArticlesComponent
