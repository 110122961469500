import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/common/layout"
import Banner from "../components/common/banner"
import Seo from "../components/common/seo"
import Articles from "../components/articles"
import bgImage from "../images/articles/banner.png"

const ContentPage = () => {
  const data = useStaticQuery(graphql`
    query {
      api {
        articles(sort: "created_at:desc") {
          id
          created_at
          slug
          title
          subtitle
          content
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  aspectRatio: 2
                )
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Conteúdo" />
      <Banner
        title="Blog"
        image={bgImage}
        links={[{ title: "Blog", path: "/blog" }]}
      />
      <Container className="half-padding-top half-padding-bottom">
        <Row className="justify-content-center">
          <Col md={12}>
            <Articles data={data.api.articles} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ContentPage
